// Here you can add other styles
body {
  /*overflow-y: scroll; */
}

.ativo-0 {
  color: red;
}

.cancelado-1 {
  text-decoration: line-through;
}

.vencido-1 {
  color: red;
}

.autocomplete-empresa-situacao-2, .autocomplete-empresa-situacao-3 {
  color: red;
}

.autocomplete-empresa-situacao-4 {
  color: orange;
}

.badge-recados {
  font-size: 14px;
}

button:focus {
  outline: none !important;
}

.caixa-fechado-N {
  color: blue;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgb(47 103 147);
}

.c-app .c-sidebar .c-sidebar-nav-link, .c-app .c-sidebar .c-sidebar-nav-dropdown-toggle {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  padding-right: 1rem !important;
}

.disable-element {
  pointer-events: none;
  opacity: 0.4;
}

.div-container-rolagem-corpo {
  background-color: white;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 430px;
  margin-bottom: 10px;
  border-bottom: 2px solid rgb(60, 75, 100);
}

.div-container-rolagem-corpo input {
  background-color: transparent !important;
}

.div-container-rolagem-titulo {
  background-color: rgb(60, 75, 100);
  color: white;
  font-weight: bold;
  padding: 8px;
  padding-right: 28px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.div-loading {
  background: url('../assets/img/ajax-loader.gif') center no-repeat;
  pointer-events: none;
}

.empresa-fatura-situacao-A {
  color: green;
}

.empresa-fatura-situacao-L {
  color: black;
}

.empresa-fatura-situacao-P {
  color: orangered;
}

.empresa-fatura-situacao-C {
  text-decoration: line-through;
}

.empresa-fatura-vencida-1 {
  background-color: #efff0030 !important;
  color: red;
  font-weight: bold;
}

.empresa-servico-vencido-1 {
  background-color: #efff0096 !important;
  color: red;
  font-weight: bold;
}

.empresa-situacao-2, .empresa-situacao-3 {
  color: red;
}

.empresa-situacao-4 {
  color: orange;
}

.excluido-1 {
  text-decoration: line-through;
  color: red;
}

.funcao-ativa-0 {
  color: red;
}

.formulario {
  width: 100%;
}

.formulario .form-group {
  margin-bottom: 0rem;
  float: left;
  width: 100%;
  margin-bottom: 0rem !important;
}

.form-group {
  margin-bottom: 0rem !important;
}

.formulario label, .form-group label  {
  display: inline-block !important;
  margin-bottom: 0px !important;
}

.formulario .form-group-checkbox {
  float: left;
}

.formulario .form-group-checkbox input {
  transform: scale(2);
  margin-left: 8px;
}

.formulario .form-group-checkbox input:hover {
  cursor: pointer;
}

.formulario .form-group-checkbox label {
  font-size: 1.2rem;
  padding-left: 1rem;
}

.formulario select,
.formulario .form-group select,
.form-group select {
  padding: 0.40rem 0.75rem !important;
  width: 100%;
}

.formulario input[type="date"],
.form-group input[type="date"] {
  padding: 0.3rem 0.75rem !important;
}

.formulario input:not([type=checkbox]), 
.form-group input:not([type=checkbox]), 
.formulario textarea, 
.formulario select,
.form-group select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formulario input:focus, 
.form-group input:focus, 
.formulario textarea:focus, 
.form-group textarea:focus, 
.formulario select:focus, 
.form-group select:focus {
  outline: 0;
  border: 1px solid #3f51b5;
}

.formulario select:hover {
  cursor: pointer;
}

.formulario button {
  margin-right: 1rem;
}

.formulario button.sem-margin-right {
  margin-right: 0rem !important;
}

.formulario .campo-invalid-feedback,
.form-group .campo-invalid-feedback {
  width: 100%;
  margin-top: 0rem !important;
  font-size: 0.85rem;
  color: #dc3545;
  font-style: italic;
}

.formulario .campo-is-invalid,
.form-group .campo-is-invalid {
  border-color: #dc3545 !important;
}

.formulario .campo-is-invalid:focus,
.form-group .campo-is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.funcionario-situacao-DE, .funcionario-situacao-CA {
  color: red;
}

.input-error {
  background-size: 26px;
  background-image: url('../assets/img/stop-03.png');
  background-repeat: no-repeat;
  background-position: right 6px center;  
}

.input-read-only input {
  background-color: #dfdada78 !important;
  pointer-events: none;
}

.input-select-transparente * {
  background-color: transparent !important;
  border: 0px !important;
}

.input-select-transparente select {
  border-color: none !important;
  box-shadow: none !important;
}

.input-select-compacto select {
  padding: 0px !important;
}

.link-img {
  opacity: 0.7;
  cursor: pointer;
}

.link-img:hover {
  opacity: 1;
}

.linkhover-container:hover .linkhover-link {
  visibility: visible;
}

.linkhover-link {
  visibility: hidden;
}

.linkhover-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.no-break-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 10px;
}

.table-lista-etilo-1 {
  margin-bottom: 10px;
}

.table-lista-etilo-1 thead tr td {
  background-color: #303f9f;
  color: white;
  padding: 5px 10px;
}

.table-lista-etilo-1 tbody tr td {
  background-color: white !important;
  padding: 5px 10px;
  border: 1px dotted #321fdb;
}

.titulo-pagina {
  font-size: 2rem;
  font-weight: bold;
  border-left: 17px solid #3f51b5;
  padding: 0 0.4rem;
  margin-bottom: 1rem;
  background-color: #3c4b64;
  color: white;
  border-radius: 6px;
  padding-left: 15px;
}

.tox-notification {
  display: none !important;
  visibility: hidden !important;
}

.tox-notifications-container * {
  display: none !important;
  visibility: hidden !important;
}

.MuiInputBase-root {
  border-radius: 0.25rem !important;
}

.MuiFormControlLabel-labelPlacementTop {
  display: flex;
}

.MuiAutocomplete-input {
  width: 88% !important;
}

.MuiAutocomplete-inputRoot:hover * {
  border-color: #3f51b5 !important;
}

.MuiAutocomplete-inputRoot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  background-color: white;
  /* border: 1px solid #ced4da; */
}

.selectautocompletar .MuiAutocomplete-inputRoot {
  border: 1px solid #ced4da; 
}

.MuiAutocomplete-inputRoot input {
  padding-left: 12px !important;
  height: 16px;
  border: none !important;
  /*margin: 1px */
}

.MuiAutocomplete-inputRoot input:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator {
  margin-right: auto !important;
}

.MuiAutocomplete-endAdornment {
  top: 0 !important;
}

.MuiButtonBase-root {
  /* height: 2.4rem; */
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiAutocomplete-input {
  padding: 0.6rem 0.75rem !important;
  border-radius: 0.25rem !important;
}

.swal2-container {
  z-index: 9000000000 !important;
}

hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;  
}

.hover-azul {
  transition-duration: 0.6s;
}

.hover-azul:hover {
  background-color: #d3e3fd;
}

.hover-cinza:hover {
  background-color: rgb(247, 246, 246);
}

.barra-titulo-pagina {
  font-size: 20px; 
  margin-bottom: 10px; 
  background-color: #dadada;
  border-radius: 5px;
  width: 100%;
}

.box-ident-empresa {
  background-color: #3c4b64;
  color: white;
  border-radius: 4px;
  padding: 10px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.box-ident-empresa .box-ident-empresa-item {
  float: left;
  margin-right: 40px;
}

.box-ident-empresa .box-ident-empresa-label {
  display: block;
}

.box-ident-empresa .box-ident-empresa-conteudo {
  font-size: 18px;
  font-weight: bold;
}

.blink-me {
  animation: blinker 1s linear infinite;
}

.blink-me-1-segundo {
  animation: blinker 1s linear 1;
}

.blink-me-2-segundos {
  animation: blinker 1s linear 2;
}

.blink-me-5-segundos {
  animation: blinker 1s linear 5;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

//https://melvingeorge.me/blog/hide-html-elements-after-certain-seconds-css
.piscar-5segundos-depois-remover {
  animation: blinker 1s linear 5, hideAnimation 0s ease-in 5s;
  animation-fill-mode: forwards;
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.etiqueta-vencido {
  background-color: red;
  color: white;
  padding: 0px 10px;
  border-radius: 6px;
}

.etiqueta-vencendo {
  background-color: blue;
  color: white;
  padding: 0px 10px;
  border-radius: 6px;
}

.tox-statusbar__branding {
  display: none;
}

button.MuiTab-root  {
  min-width: 1px !important;
}

.zebra-1 {
  background-color: white;
}

.zebra-0 {
  background-color: rgb(240, 242, 244); 
}

.text-color-red {
  color: red;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.text-link-2:hover {
  cursor: pointer;
  color: rgb(29, 52, 153);
}

.text-link-3 {
  font-size: 1rem;
  padding: 2px 10px;
  border-radius: 8px;
}

.text-link-3:hover {
  cursor: pointer;
  background-color: rgb(228, 231, 231);
  font-weight: bold;
}

.text-link-3-selecionado-true {
  background-color: #d3e3fd;
  font-weight: bold;
}

.text-link-4 {
  transition-duration: 0.3s;
}

.text-link-4:hover {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.text-badge-red {
  background-color: red;
  font-weight: bold;
  color: white;
  border-radius: 18px;
  padding: 6px 14px;
}